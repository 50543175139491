import './App.css';
import EmailSignature from './EmailSignature';
import ReactDOMServer from 'react-dom/server';
import {useState} from 'react';

function toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

function fromBinary(encoded) {
    const binary = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export default function App({serializedState}) {

    let defaultState;
    let showSignatureOnly = false;

    try {
        defaultState = JSON.parse(fromBinary(serializedState));
        showSignatureOnly = true;
    } catch {
        defaultState = {
            nameAndSurname: 'Meno Priezvisko, SDB',
            position: 'Pozícia',
            positionEnglish: '',
            mobile: '+421 123 456 789',
            address: 'Miletičova 7, 821 08 Bratislava'
        };
    }

    const [state, setState] = useState(defaultState);

    const [copied, setCopied] = useState(false);
    let copyTimeout;

    const fields = [
        {
            name: 'nameAndSurname',
            label: 'Meno a priezvisko'
        },
        {
            name: 'position',
            label: 'Pozícia'
        },
        {
            name: 'positionEnglish',
            label: 'Pozícia (po anglicky)'
        },
        {
            name: 'mobile',
            label: 'Mobil',
            hint: 'Použi medzinárodný formát. Napríklad: +421 123 456 789'
        },
        {
            name: 'address',
            label: 'Adresa',
            hint: 'Použi formát Ulica číslo, PSČ Obec. Napríklad: Miletičova 7, 821 08 Bratislava'
        }
    ];

    const Signature = (
        <EmailSignature {...state}/>
    );
    const source = ReactDOMServer.renderToStaticMarkup(Signature);

    function handleInput(event) {
        setState({...state, [event.target.name]: event.target.value});
    }

    function copyToClipboard() {
        const data = [new ClipboardItem({
            'text/html': new Blob([source], {type: 'text/html'}),
            'text/plain': new Blob([source], {type: 'text/plain'})
        })];

        navigator.clipboard.write(data).then(function () {
            setCopied(true);
            clearTimeout(copyTimeout);
            copyTimeout = setTimeout(() => setCopied(false), 10000);
        }, function () {
            alert('Podpis sa nepodarilo vložiť do schránky (clipboard).');
        });
    }

    return showSignatureOnly ? Signature : (
        <div className="app">
            <h1>Generátor podpisu emailu</h1>
            <h2>Zadaj svoje údaje</h2>
            {fields.map((field, index) => (
                <div key={index} className="form-group">
                    <label htmlFor={field.name} className="form-label">
                        {field.label}:
                    </label>
                    {field.hint && <div className="form-help">{field.hint}</div>}
                    <input
                        type="text"
                        id={field.name}
                        name={field.name}
                        value={state[field.name]}
                        className="form-control"
                        onInput={handleInput}
                    />
                </div>
            ))}

            <h2>Náhľad</h2>
            <div className="preview">{Signature}</div>


            <div className="code">
                <div style={{
                    padding: 20,
                    backgroundColor: '#ededed',
                    marginTop: 30,
                    marginBottom: 60
                }}>
                    <h2 style={{
                        margin: 0,
                        marginBottom: 20
                    }}>Obsah na vloženie podpisu do emailu</h2>
                    <button onClick={copyToClipboard}>Klikni na skopírovanie do schránky</button>

                    <div style={{
                        paddingTop: 20,
                        display: copied ? 'block' : 'none'
                    }}>Obsah bol skopírovaný do schránky.<br /><strong>Do emailu ho vložíš cez CTRL+V.</strong>
                    </div>
                </div>

                <textarea
                    style={{
                        width: '100%',
                        height: '100px',
                        marginBottom: '1rem'
                    }}
                    value={source}
                    readOnly={true}
                />


                <a href={`?${toBinary(
                    JSON.stringify(state))}`} className="button" target="_blank" rel="noreferrer" style={{
                    backgroundColor: 'white',
                    padding: 0,
                    border: 'none',
                    textDecoration: 'underline',
                    color: 'black'
                }}>Náhľad emailu v novom tabe</a>
            </div>
            <br/>
        </div>
    );
}
